import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import SectionHero from '../../components/section-hero';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';

const ClinicalGenomicsStudyPage = () => (
  <Layout className="study">
    <SEO title="Clinical Genomics | St. Jude Cloud" />
    <SectionHero className="study__hero" fileName="clinical-genomics-study-hero-image.jpg">
      <Container className="study__hero__container">
        <h1>Clinical Genomics</h1>
      </Container>
    </SectionHero>
    <Section className="study__section">
      <Row>
        <Col>
          <h2 id="about">About the Study</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Eligible cancer patients treated at St. Jude Children&apos;s Research Hospital are
            offered three-platform next-generation sequencing testing. This testing generally
            includes whole-genome sequencing (WGS) and whole-exome sequencing (WES) of tumor and
            normal tissue, as well as RNA sequencing (RNA-Seq) of their tumor. Many of those
            patients consent to research data sharing so that their information can be leveraged to
            find cures for future patients. The <b>Clinical Genomics</b> dataset is comprised of
            data from these samples and is broken down into three distinct project phases.
          </p>
          <p>
            <ul>
              <li>
                The <b>Clinical Pilot</b> project was a retrospective study that evaluated the
                accuracy and demonstrated the feasibility of three-platform sequencing in a CAP/CLIA
                setting. The findings of this project were published in{' '}
                <a href="https://www.ncbi.nlm.nih.gov/pubmed/30262806">
                  <em>Nature Communications</em>
                </a>
                .
              </li>
              <li>
                The <b>Genomes 4 Kids (G4K)</b> followed the Clinical Pilot. The goal of this
                prospective study was to determine whether the three-platform sequencing protocol
                laid out in the Clinical Pilot project could generate results on a clinical timeline
                in practice and to evaluate the prevalence of actionable findings. The study
                concluded with just over 300 patients, and the findings were published in{' '}
                <a href="https://cancerdiscovery.aacrjournals.org/content/early/2021/07/21/2159-8290.CD-20-1631">
                  <em>Cancer Discovery</em>
                </a>
                .
              </li>
              <li>
                After the completion of G4K, clinical genomics became a standard service.{' '}
                <b>Real-time Clinical Genomics (RTCG)</b> is a{' '}
                <a href="https://www.stjude.org/media-resources/news-releases/2019-medicine-science-news/st-jude-cloud-to-provide-access-to-real-time-clinical-genome-sequencing-data.html">
                  first of its kind initiative
                </a>
                , whereby St. Jude began releasing data from the clinical NGS service consented for
                research use to St. Jude Cloud in monthly batches to give researchers access to
                valuable data as quickly as possible. Subsequent publications are expected in the
                future.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
    </Section>
    <Section gray>
      <Row>
        <Col>
          <h2 id="apps">Apps</h2>
          <p>
            Data from the Clinical Genomics study is included in the following St. Jude Cloud
            applications.
          </p>
        </Col>
      </Row>
      <Row className="study__apps">
        <CardAppPlatform
          lg={6}
          description="Next-generation sequencing data from the Clinical Genomics study is available to analyze in the cloud."
        />
        <CardAppVizComm
          lg={6}
          description="Multiple visualizations have been developed to accompany papers published as part of the Clinical Genomics study."
        />
      </Row>
    </Section>
    <Section className="study__section--citation">
      <Row>
        <Col>
          <h2 id="citation">Cite the Program</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Please visit{' '}
            <a href="https://university.stjude.cloud/docs/citing-stjude-cloud/">
              our dedicated documentation page on how to cite St. Jude Cloud
            </a>{' '}
            when publishing manuscripts utilizing data or information from this resource.
          </p>
          <p>
            Questions or Feedback? Contact us at{' '}
            <a href="mailto:support@stjude.cloud">support@stjude.cloud</a>.
          </p>
        </Col>
      </Row>
    </Section>
  </Layout>
);
export default ClinicalGenomicsStudyPage;
